:global {
    .lz-custom-upload {
        position: relative;
        display : inline-block;
    }

    .files-drag-sort {
        position  : absolute;
        z-index   : 99;
        list-style: none;
        padding   : 0;
        height    : 0;

        &.draging {
            height: initial;
        }

        .file-drag-sort-item {
            margin-top: 10px;

            &>div {
                padding            : 0 5px;
                color              : rgba(255, 255, 255, 0.85);
                background-color   : rgba(0, 0, 0, 0.5);
                transform          : translateY(-21px);
                transition-duration: 0.15s;
            }
        }
    }

    .file-drag-sort-item {
        position               : relative;
        list-style             : none;
        overflow               : hidden;
        cursor                 : move;
        color                  : #ffffff;
        border-top-left-radius : 4px;
        border-top-right-radius: 4px;
    }

    .file-draging-sort-item {
        color           : rgba(255, 255, 255, 0.85);
        border-radius   : 4px;
        background-color: rgba(0, 0, 0, 0.5);
        display         : flex;
        justify-content : center;
        align-items     : center;
        z-index         : 9;
    }

    //=========== picture-card ===========
    .ant-upload-select-picture-card {
        .files-drag-sort {
            left : 0;
            top  : -19px;
            width: max-content;
        }

        .file-drag-sort-item {
            float : left;
            width : 104px;
            height: 20px;
            margin: 0 8px 92px 0;

            &:active {
                height       : 104px;
                margin-bottom: 8px;
                border-radius: 4px;
            }

            // &:hover {
            //     div {
            //         transform: translateY(0);
            //     }
            // }

            &>div {
                color                 : rgba(255, 255, 255, 0.85);
                background-color      : rgba(0, 0, 0, 0.5);
                transform             : translateY(0);
                // transition-duration: 0.15s;
            }
        }

        .files-drag-sort.draging {
            .file-drag-sort-item {
                height       : 104px;
                margin-bottom: 8px;
            }

            .file-drag-sort-item {
                div {
                    display: none;
                }
            }
        }
    }

    .ant-upload-select-picture-card {
        .files-drag-sort {
            left : 0;
            top  : -19px;
            width: max-content;
        }

        .file-drag-sort-item {
            float : left;
            width : 104px;
            height: 20px;
            margin: 0 8px 92px 0;

            &:active {
                height       : 104px;
                margin-bottom: 8px;
                border-radius: 4px;
            }

            // &:hover {
            //     div {
            //         transform: translateY(0);
            //     }
            // }

            &>div {
                color                 : rgba(255, 255, 255, 0.85);
                background-color      : rgba(0, 0, 0, 0.5);
                transform             : translateY(0);
                // transition-duration: 0.15s;
            }
        }

        .files-drag-sort.draging {
            .file-drag-sort-item {
                height       : 104px;
                margin-bottom: 8px;
            }

            .file-drag-sort-item {
                div {
                    display: none;
                }
            }
        }
    }

    //=========== picture ===========
    .ant-upload-select-picture {
        .files-drag-sort {
            width : 90%;
            height: max-content;
        }

        .file-drag-sort-item {
            width                  : 100%;
            height                 : 66px;
            margin-top             : 8px;
            border-top-right-radius: 0;

            &:first-of-type {
                margin-top: 12px;
            }

            &:active {
                border-radius: 4px;
            }

            &:hover {
                div {
                    transform: translateY(0);
                }
            }

            div {
                text-align: center;
            }
        }

        .files-drag-sort.draging {
            .file-drag-sort-item {
                margin-bottom: 8px;
            }

            .file-drag-sort-item {
                div {
                    display: none;
                }
            }
        }
    }

    //=========== text ===========
    .ant-upload-select-text {
        .files-drag-sort {
            width    : 50%;
            left     : 0;
            right    : 0;
            margin   : auto;
            min-width: 80px;
        }

        .file-drag-sort-item {
            width        : 100%;
            height       : 22px;
            margin-top   : 8px;
            border-radius: 0;

            &:first-of-type {
                margin-top: 12px;
            }

            &:active {
                border-radius: 4px;
            }

            &:hover {
                div {
                    transform: translateY(0);
                }
            }

            div {
                text-align: center;
            }
        }

        .files-drag-sort.draging {
            .file-drag-sort-item {
                margin-bottom: 8px;
            }

            .file-drag-sort-item {
                div {
                    display: none;
                }
            }
        }
    }

    .lz-custom-upload.singleHidden {

        &>.ant-upload-list {
            &>.ant-upload-list-item {
                display: none !important;
            }

            &>.ant-upload-list-item:last-of-type {
                display: block !important;
            }
        }

        &>.ant-upload-list>.ant-upload.ant-upload-select {
            display: none;
        }

        &.startSelectCard {
            &>.ant-upload-list>.ant-upload.ant-upload-select {
                display: table !important;
            }
        }
    }

    .lz-custom-upload.hiddenDisabled {
        .ant-upload-disabled {
            display: none;
        }
    }
}