.container {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-top: 10px;
    height: 30px;
    .pagecount {
        display: flex;
        align-items: center;
        width: 200px;
        .total {
            margin-left: 5px;
            margin-right: 5px;
        }
        .pagesize {
            margin-left: 5px;
            margin-right: 5px;
        }
    }
    .pageaction {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .pagejump {
            width: 116px;
        }
        .pagination {
            margin-left: 20px;
        }
    }
}

.pagesize :global {
    .ant-input-number {
        width: 60px;
    }
}

.pagejump :global {
    .ant-input-number {
        width: 60px;
        margin-left: 5px;
        margin-right: 5px;
    }
}
