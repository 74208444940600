.container {
  display: flex;
  color: #999999;
  font-size: 14px;
  .tab {
    flex: 1;
  }
  .item {
    cursor: pointer;
    padding-bottom: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    .total {
      margin-left: 4px;
      color: #999999;
      font-size: 16px;
    }
  }
  .actived {
    color: #252525;
    border-bottom: solid 2px #f8b129;
    .total {
      color: #ff2b15;
    }
  }
  span.spanNode {
    display: inline-block;
    margin: 0 4px;
  }
}
