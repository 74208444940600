.container {
    .line {
        display: flex;
        flex: 1;
        border-bottom: solid 1px #d7d9df;
    }
    .button {
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;
    }
}