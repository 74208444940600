
.container {
    display: flex;
    align-items: center;

    .label {
        white-space: nowrap;
        display: block;
        color: #252525;
        font-size: 14px;
        white-space: nowrap;
    }

    .selectbox{
        margin-left: 10px;
        .input {
            height: 30px;
        }
    }

    .select :global {
        .ant-select-selection, .ant-select-selection__rendered {
            height: 30px;
        }
    }
}