.container {
    display: flex;
    align-items: center;
    .label {
        display:block;
        font-size: 14px;
        white-space: nowrap;
        color: #252525;
    }
    .picker {
        margin-left: 10px;
    }
    .star {
        color: #ff2b15;
    }
}