.navcard {
    width: 210px;
    border-bottom: solid 1px #3f3f3f;
    overflow: hidden;
    // background-color: #032247;
    .cardhome {
        height: 50px;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        background-color: #353535;
        .icon {
            width: 16px;
            height: 16px;
            margin-left: 20px;
            user-select: none;
        }
        .title {
            margin-left: 20px;
            font-size: 14px;
            color: white;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
        .expand {
            width: 10px;
            height: 6px;
            position: absolute;
            right: 30px;
        }
        // &:hover {
        //     background-color: #323232;
        // }
    }
    .carditem {
        // margin-left: 10px;
        // margin-right: 10px;
        height: 40px;
        display: flex;
        align-items: center;
        background-color: #323232;
        cursor: pointer;
        // &:hover {
        //     background-color: #3F3F3F;
        // }
        .itemtitle {
            margin-left: 58px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            color: white;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
    }
    .active_carditem {
        background-color: #3f3f3f;
        // &:hover {
        //     background-color: rgba($color: #3F3F3F, $alpha: 1);
        // }
    }

    .carditem_animate {
        transition: margin-top 0.2s linear;
    }

    .carditem_actived {
        margin-top: 0px;
    }
}

.navcard_actived {
    // border-bottom: solid 1px #3F3F3F;
}

.cardhome_actived {
    box-shadow: 0px 1px 8px #000;
    background-color: #37bb8f !important;
    // border-radius: 6px;
    margin-left: 2px;
    margin-right: 2px;
}

.title_actived {
    font-size: 18px !important;
}
