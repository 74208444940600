@import "@/assets/css/table.scss";

.action {
    display: flex;
    .item {
        margin-right: 10px;
        color: #37BB8F;
        cursor: pointer;
    }
}
