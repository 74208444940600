.container {
  display: flex;
  align-items: center;
  .label {
    display: inline-block;
    color: #252525;
    font-size: 14px;
    display: flex;
  }
  .select {
    margin-left: 10px;
    flex: 1;
    .value {
      border: 1px solid #d9d9d9;
      padding: 4px 11px;
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        border-color: #37BB8F;
      }
    }
    .placeholderShow {
      color: rgba(0, 0, 0, 0.4);
    }
  }
}

.star {
  color: #ff2b15;
}

// .select :global {
//     .ant-select-selection, .ant-select-selection__rendered {
//         height: 30px;
//     }
// }
