.container {
    display: flex;
    align-items: center;
    .label {
        white-space: nowrap;
        display: block;
        color: #252525;
        font-size: 14px;
        white-space: nowrap;
    }
    .inputbox {
        margin-left: 10px;
        .input {
            height: 30px;
        }
    }
    .star {
        color: #ff2b15;
    }
    .suffix {
        margin-left: 6px;
    }
    &::global {
        .ant-input-number-handler-wrap {
            display: none;
        }
    }
}
