.container {
    min-height: 36px;
    // border-top: solid 1px #dddfe5;
    // background-color: #1890ff;
    position: relative;
    min-width: 350px;
    display: flex;
    align-items: center;
    color: #686868;
    border-radius: 6px 6px 0px 0px;
    .left {
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 10px;
        .module_name {
            font-size: 16px;
            font-weight: bold;
            color: #fff;
        }
    }
    .right {
        margin-left: 6px;
        display: flex;
        align-items: center;
        flex: 1;
        height: 100%;
        .icon {
            width: 10px;
            height: 12px;
        }
        .icon_label {
            margin-left: 5px;
            color: #37bb8f;
        }
    }

    .breadcrumbitem {
        color: #37bb8f !important;
    }

    .breadcrumbitemhover {
        color: #37bb8f !important;
        &:hover {
            border-bottom: 1px solid #37bb8f;
            padding-bottom: 1px;
        }
    }

    :global {
        .ant-breadcrumb-separator {
            color: #37bb8f;
        }
    }
}
