.container {
  display: flex;
  height: 100%;
  .left {
    height: 100%;
  }
  .right {
    flex: 1;
  }
}
