.statsPanel {
    display: flex;
    flex-wrap:wrap;
}
.statsPanel > div {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items:center;
    justify-content:center;
    margin:0 16px 16px 0;
    width: 194px;
height: 146px;
color: #333;
background-color: rgba(22, 119, 255, .1);
border-image: linear-gradient(180deg, #40bcf9, #1e87f0);
border-radius: 16px;
    font-size: 14px;

}
.statsPanel > div p {margin: 0}
.statsPanel > div p:nth-child(1) {
    color: #666666
}
.statsPanel > div p:nth-child(3) {
    color: #999
}


.statsPanel > div p:nth-child(2) {
    font-size: 30px;
    font-weight: 500;
    margin: 10px 0;
}
.timelineCharts div[style='margin-right: -20px;'] { display:none }