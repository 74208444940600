.bg {
    background: url(../../../assets/image/beijing.png) no-repeat;
    background-size: cover;
    overflow: hidden;
    width: 100%;
    height: 100%;

    .layout {
        width: 100%;
        height: 100%;
        background-color: #000000;
        opacity: 0.3;
    }

    .companyname {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 36px;
        left: 50%;
        transform: translate(-50%, 0);
        color: #ffffff;
        font-size: 14px;
    }
}
