.moreStyles {
    color: #37BB8F;
    background: rgba(237,243,255,1);
    margin: 4px;
    border-radius: 4px;
    :global {
        .ant-dropdown-menu-item-active {
            background: rgba(203,234,255,1);
        }
    } 
}

.label {
    display: inline-block;
    margin-right: 5px;
}

.input {
    display: none;
}

.icon {
    display: inline-block;
}