.selectedDetail {
    margin-bottom: 10px;
    padding-left: 10px;
    height: 30px;
	background-color: #d7e8ff;
    border: solid 1px #bedaff;
    display: flex;
    align-items: center;
    .info {
        margin-left: 10px;
        font-size: 12px;
        color: #000000;
    }
    .clean {
        font-size: 12px;
        margin-left: 10px;
        color: #1078ff;
        cursor: pointer;
    }
}