.container {
  display: flex;
  align-items: center;
  border-bottom: solid 1px #dddfe5;
  padding-top: 20px;
  padding-bottom: 20px;
  .avatar {
    display: flex;
    align-items: center;
    width: 450px;
    .tbox {
      margin-left: 10px;
      .title {
        font-size: 16px;
        color: #252525;
      }
      .subTitle {
        font-size: 12px;
        color: #999999;
      }
    }
  }
  .content {
    flex: 1;
  }
}
