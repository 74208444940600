.container {
    & :global {
        .ant-btn-primary {
            width: 100%;
            height: 30px;
            font-size: 14px;
            border-radius: 4px;
            font-family: Microsoft YaHei;
        }
    }
}
