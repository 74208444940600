@import "@/assets/css/table.scss";

.uploadAvatar {
  position: absolute;
  top: 70px;
  right: 200px;
  margin-left: 10px;
  border: solid 1px #999;
  border-radius: 60px;
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:hover {
    .avatarText {
      display: block;
    }
    .layer {
      width: 120px;
      height: 120px;
      display: block;
    }
  }
  .avatarText {
    position: relative;
    top: -70px;
    color: white;
    display: none;
    pointer-events: none;
  }
  .layer {
    width: 120px;
    height: 120px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 60px;
    position: absolute;
    display: none;
    pointer-events: none;
  }
}
