.container {
    display: flex;
    align-items: center;
    .line {
        width: 4px;
        height: 16px;
        border-left: solid 4px #3094FB;
    }
    .label {
        margin-left: 10px;
        font-size: 16px;
        color: #252525;
    }
}