@import '@/assets/css/table.scss';

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.table_body {
    :global {
        .ant-input-number-handler-wrap {
            display: none;
        }
    }
}