// 斑马线
.tdcolor {
  // background-color: #f1f7f9;
}

// 表格高度自适应
.flexBoxContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tableContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 0;
  padding-top: 10px;
  min-height: 200px;
}

.scrollContainer {
  flex: 1;
  overflow-y: auto;
}

.scrollFlexBox {
  overflow: hidden;
}
