@import '@/assets/css/table.scss';

.action {
    display: flex;
    justify-content: center;
    .item {
        margin-left: 10px;
        color: #37BB8F;
        cursor: pointer;
    }
}
// :global {
// .ant-table-tbody > tr.ant-table-row-selected > td {
//     background:none;
// }
// .ant-table-cell-fix-right {
//     background-color: #fff!important;
// }
// }
.tdRedColor{ 
    -webkit-animation: twinkling 1s infinite ease-in-out;
    background-color:rgba(253, 147, 147, 1);
    } 
    .animated{ 
    -webkit-animation-duration: 1s; 
    animation-duration: 1s; 
    -webkit-animation-fill-mode: both; 
    animation-fill-mode: both 
    } 
    @-webkit-keyframes twinkling{ 
    0%{ 
    opacity: 0.5; 
    } 
    100%{ 
    opacity: 1; 
    } 
    } 
    @keyframes twinkling{ 
    0%{ 
    opacity: 0.5; 
    } 
    100%{ 
    opacity: 1; 
    } 
    }
