.container {
    display: flex;
    align-items: center;
    cursor: pointer;
    .icon {
        width: 24px;
        height: 24px;
    }
    .label {
        white-space: nowrap;
        margin-left: 10px;
        color: #1078ff;
    }
}