.container {
    .mark {
        padding-left: 4px;
        padding-right: 4px;
    }
    .title {
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
}
