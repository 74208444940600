@import '@/assets/css/table.scss';
.handle {
    display: inline-block;
    width: 500px;
    .rows {
        display: flex;
        margin-top: 20px;
        .label {
            width: 90px;
        }
    }
}