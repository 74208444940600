.login {
    position : absolute;
    width    : 430px;
    top      : 50%;
    left     : 50%;
    transform: translate(-50%, -50%);

    .logo {
        width          : 100%;
        display        : flex;
        justify-content: center;
        margin-bottom  : 10px;

        .logo_img {
            width : 60px;
            height: 52px;
        }
    }

    .sys_name {
        text-align   : center;
        color        : #ffffff;
        font-size    : 24px;
        margin-bottom: 20px;
    }

    .userinput {
        height: 330px;

        .bglayer {
            position          : absolute;
            width             : 100%;
            height            : 276px;
            background-color  : white;
            border-radius     : 8px;
            -moz-box-shadow   : 8px 8px 8px rgba(30, 72, 111, 0.35);
            -webkit-box-shadow: 8px 8px 8px rgba(30, 72, 111, 0.35);
            box-shadow        : 8px 8px 8px rgba(30, 72, 111, 0.35);
        }

        .handle {
            position: absolute;
            width   : 100%;
            padding : 20px;

            .title {
                font-weight  : bold;
                margin-top   : 10px;
                margin-bottom: 20px;
                font-size    : 16px;
            }
        }

        .rememberme {
            margin-top: 20px;
            display   : flex;

            .forgetPw {
                color          : #1890ff;
                font-size      : 16px;
                flex           : 1;
                display        : flex;
                justify-content: flex-end;
                cursor         : pointer;
            }
        }

        .submit {
            margin-top: 30px;
        }

        .error {
            display    : flex;
            align-items: center;
            margin-top : 8px;

            .jinzhi_img {
                width : 12px;
                height: 12px;
            }

            .jinzhi_text {
                margin-left: 10px;
                font-size  : 14px;
                font-family: SimHei;
                color      : #575757;
            }
        }
    }
}

.rememberme :global {
    .ant-checkbox-wrapper {
        font-size   : 16px;
        font-family : Microsoft YaHei;
        color       : #343434;
        padding-left: 10px;
    }
}

.submit :global {
    .ant-btn-primary {
        width        : 100%;
        height       : 40px;
        font-size    : 18px;
        border-radius: 8px;
        font-family  : Microsoft YaHei;
    }
}

.container :global {
    .ant-input-prefix {
        font-size: 20px;
        left     : 20px;
    }

    .ant-input {
        height       : 46px;
        border-radius: 8px;
        font-size    : 16px;
        color        : #252525;
        font-family  : Microsoft YaHei;
    }

    .ant-input:not(:first-child) {
        padding-left: 70px;
    }
}

.password :global {
    margin-top: 20px;
}