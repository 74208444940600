@import "@/assets/css/modal.scss";
.container:hover {
    .closeicon {
        filter: opacity(100);
        opacity: 1;
    }
    .viewerFooter {
        height: 4vh;
        filter: opacity(100);
        opacity: 1;
        overflow: visible;
    }
}
.container:hover + .containerViewer ,.containerViewer:hover{
    .viewerFooter {
        height: 4vh;
        filter: opacity(100);
        opacity: 1;
        overflow: visible;
    }
}
.container {
    position: relative;
    .currentImg {
        -webkit-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
    }
    .videos {
        width: 100%;
        height: 100%;
        outline: none;
    }
    .audios {
        background-color: #F1F3F4;
        width: 100%;
        height: 84px;
        outline: none;
    }
    .pdf {
        width: 100%;
        height: 687px;
        outline: none;
    }
    .closeicon {
        position: absolute;
        top: 7px;
        right: 7px;
        width: 32px;
        height: 32px;
        z-index: 999;
        img {
            width: 100%;
            cursor: pointer;
        }
        -ms-transition: all 0.2s linear;
        -moz-transition: all 0.2s linear;
        -webkit-transition: all 0.2s linear;
        transition: all 0.2s linear;
        filter: opacity(0);
        opacity: 0;
    }
}
.containerViewer {
    position: relative;
    //viewer.css
    .viewerFooter {
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.25);
        width: 100%;
        height: 0;
        -ms-transition: all 0.2s linear;
        -moz-transition: all 0.2s linear;
        -webkit-transition: all 0.2s linear;
        transition: all 0.2s linear;
        overflow: hidden;
    }
    .viewerToolbar {
        position: absolute;
        top: 50%;
        left: 50%;
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        overflow: hidden;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;    

        > li {
            float: left;
            width: 24px;
            height: 24px;
            margin-right: 8px;
            -webkit-border-radius: 50%;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.5);
            cursor: pointer;
        }
        > li:last-child {
            margin-right: 0;
        }
        > li:hover {
            background-color: #000;
        }
    }
    .viewerZoomIn,
    .viewerZoomOut,
    .viewerOnetoOne,
    .viewerReset,
    .viewerRotateLeft,
    .viewerRotateRight,
    .viewerFlipHorizontal,
    .viewerFlipVertical {
        &::before {
            background-image: url("../../../assets/image/nodata/icons.png");
            background-repeat: no-repeat;
            background-size: 280px;
            color: transparent;
            display: block;
            font-size: 0;
            height: 24px;
            line-height: 0;
            width: 24px;
            margin-left: 2px;
            margin-top: 2px;
        }
    }

    .viewerZoomIn::before {
        background-position: 0 0;
        content: "Zoom In";
    }

    .viewerZoomOut::before {
        background-position: -20px 0;
        content: "Zoom Out";
    }

    .viewerOnetoOne::before {
        background-position: -40px 0;
        content: "One to One";
    }

    .viewerReset::before {
        background-position: -60px 0;
        content: "Reset";
    }

    .viewerRotateLeft::before {
        background-position: -140px 0;
        content: "Rotate Left";
    }

    .viewerRotateRight::before {
        background-position: -160px 0;
        content: "Rotate Right";
    }

    .viewerFlipHorizontal::before {
        background-position: -180px 0;
        content: "Flip Horizontal";
    }

    .viewerFlipVertical::before {
        background-position: -200px 0;
        content: "Flip Vertical";
    }
}
