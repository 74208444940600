ul {
	margin: 0;
}

input::-ms-clear {
	display: none;
}

html {
	color: #000;
	background: #FFF;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
	margin: 0;
	padding: 0;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

fieldset,
img {
	border: 0;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
	font-style: normal;
	font-weight: normal;
}

ol,
ul {
	list-style: none;
}

caption,
th {
	text-align: left;
}

q:before,
q:after {
	content: '';
}

abbr,
acronym {
	border: 0;
}

#requestLoading {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	// background-color: rgba($color: #000, $alpha: 0.5);
	display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */  
	display: -moz-box; /* Firefox 17- */  
	display: -webkit-flex; /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */  
	display: -moz-flex; /* Firefox 18+ */  
	display: -ms-flexbox; /* IE 10 */  
	display: flex; /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */  
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	font-size: 20px;
	z-index: 1000;
}
.antd-pro-charts-pie-percent { display:none }
.antd-pro-charts-pie-pie .antd-pro-charts-pie-value { right:initial }