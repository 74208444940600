@import "@/assets/css/global.scss";

.container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: auto;

    .header {
        height: 48px;
        display: flex;

        .logo {
            min-width: 208px;
            width: 208px;
            white-space: nowrap;
            background-color: #323232;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ffffff;
            font-size: 16px;
            font-weight: bold;
        }

        .help {
            flex: 1;
            position: relative;
            min-width: 350px;
            display: flex;
            align-items: center;
            background-color: #ffffff;
            box-sizing: border-box;
            border-bottom: solid 1px #dadada ;

            .other {
                display: flex;
                align-items: center;
                flex: 1;
                justify-content: flex-end;
                margin-right: 10px;

                .companyName {
                    margin-right: 50px;
                    white-space: nowrap;
                }

                .search {
                    margin-right: 30px;
                    cursor: pointer;

                    .search_img {
                        width: 22px;
                        height: 22px;
                    }
                }

                .alert {
                    margin-right: 30px;
                    cursor: pointer;

                    .alert_img {
                        width: 22px;
                        height: 22px;
                    }
                }

                .userinfo {
                    margin-right: 10px;
                    display: flex;
                    align-items: center;

                    .userinfo_name {
                        font-size: 14px;
                        white-space: nowrap;
                        margin-left: 10px;
                        font-family: Microsoft YaHei;
                    }
                }
            }

            .logout {
                display: flex;
                align-items: center;
                margin-right: 30px;
                color: #ffffff;
                cursor: pointer;
                white-space: nowrap;
                .icon {
                    padding: 10px;
                }
            }
        }
    }

    .body {
        flex: 1;
        display: flex;
        height: calc(100vh - 48px);
        max-height: calc(100vh - 48px);

        .nav {
            transition: width 0.2s linear;
            overflow-x: hidden;
            overflow-y: hidden;
            height: calc(100vh - 48px);
            max-height: calc(100vh - 48px);
        }

        .content {
            display: flex;
            flex: 1;
            z-index: 2;
            overflow-x: auto;
            height: calc(100vh - 48px);
            max-height: calc(100vh - 48px);

            .sbox {
                display: flex;
                flex-direction: column;
                justify-content: center;
                background-color: #fafafa;
                padding-left: 6px;
                padding-right: 4px;

                .sitem {
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 20px;
                    height: 100px;
                    background-color: #ffffff;
                    border-radius: 10px;
                    box-shadow: 1px 1px 2px rgba(30, 72, 111, 0.35);

                    .letfsicon {
                        width: 12px;
                        height: 22px;
                        background-image: url("../../assets/image/left.png");
                    }

                    &:hover {
                        background-color: #2581c8;

                        .letfsicon {
                            background-image: url("../../assets/image/left_hover.png");
                        }
                    }

                    .rightsicon {
                        width: 12px;
                        height: 22px;
                        background-image: url("../../assets/image/right.png");
                    }

                    &:hover {
                        background-color: #2581c8;

                        .rightsicon {
                            background-image: url("../../assets/image/right_hover.png");
                        }
                    }
                }
            }

            .rbox {
                display: flex;
                height: 100%;
                flex: 1;
                flex-direction: column;
                min-width: 1000px;
                background-color: #fafafa;
                padding-left: 0px;
                padding-right: 10px;
                // padding-top: 10px;
                padding-bottom: 10px;
                overflow-x: auto;
                overflow-y: auto;
                // min-width: 1600px;
                min-height: 468px;

                .routerstyle {
                    border-left: solid 1px #e0e0e0;
                    overflow-y: auto;
                    position: relative;
                    height: 100%;
                    background-color: white;
                    box-shadow: 2px 2px 8px rgba(30, 72, 111, 0.35);
                    border-radius: 0px 0px 6px 6px;
                    padding-left: 10px;
                    padding-right: 10px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
            }
        }
    }
}
