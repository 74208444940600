/**
 * AntDesign 全局样式
 */
html :global {
    // 表格
    .ant-table-thead > tr > th {
        padding: 8px 10px;
        font-size: 12px;
        font-weight: bold;
    }
    .ant-table-tbody > tr > td {
        padding: 6px 10px;
        font-size: 12px;
    }

    .ant-table-container {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .ant-table-body {
        flex: 1;
    }

    .ant-table-scroll {
        height: 100%;
        overflow-y: hidden;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
    }
    .ant-table-content,
    .ant-table,
    .ant-spin-container,
    .ant-spin-nested-loading,
    .ant-table-wrapper,
    .OrderTable_table_box__2lOaE,
    .OrderTable_container__87UGQ {
        width: 100%;
        height: 100%;
    }
    .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
        flex: 1;
    }
    .ant-table-wrapper {
        display: flex;
        flex: 1;
        overflow-y: auto !important;
        border: 1px solid #e8e8e8;
    }

    // 弹框
    // .ant-modal-header {
    //     background-color: #47b0e7;
    // }
    .ant-modal-title {
        color: white;
    }
    .ant-modal-close {
        color: white;
        &:hover {
            color: white;
        }
    }
    .ant-modal-body {
        padding: 30px;
    }

    // tabs
    .ant-tabs-nav {
        margin-bottom: 4px !important;
    }
}
