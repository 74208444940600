.container {
    display: flex;
    padding-top: 7px;
    padding-bottom: 7px;
    white-space: nowrap;
    .label {
        font-size: 14px;
        color: #999999;
        white-space: nowrap;
    }
    .value {
        font-size: 14px;
        color: #252525;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
