.modalmedia {
    .media {
        position: relative;
        float: left;
        width: 100px;
        height: 100px;
        border: 1px solid #d9d9d9;
        background: #fafafa;
        margin-right: 15px;
        -webkit-border-radius: 4px;
        border-radius: 4px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        :global {
            .anticon svg {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                opacity: 0;
                margin: auto;
                font-size: 20px;
                cursor: pointer;
            }
        }
        video {
            width: 100%;
            height: 100%;
        }
    }
    .audiobg {
        background: #e6e5e5 !important;
    }
    .mediaData {
        position: relative;
        float: left;
        width: 100px;
        height: 100px;
        border: 1px solid #d9d9d9;
        margin-right: 15px;
        background: #fafafa;
        -webkit-border-radius: 4px;
        border-radius: 4px;
        overflow: hidden;
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            max-width: 100px;
            max-height: 100px;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }
        .nodataSource {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0;
            text-align: center;
            z-index: 99;
            visibility: hidden;
            opacity: 0;
            filter: opacity(0);
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;
        }
    }
    .mediaData:hover {
        .nodataSource {
            text-align: center;
            color: #fff;
            background: #000;
            font-size: 12px;
            height: 25px;
            line-height: 25px;
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;
            visibility: visible;
            opacity: 0.6;
            filter: opacity(65);
        }
    }
    .media:hover::after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 100;
        z-index: 1;
    }
    .media:hover {
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        :global {
            .anticon svg {
                opacity: 100;
                z-index: 9;
            }
        }
    }
    .videos {
        width: 100%;
        height: 100%;
    }
    .audios {
        width: 100%;
    }
    .pdf {
        width: 100%;
        height: 100%;
    }
    :global {
        .previewtype {
            .ant-modal {
                padding-bottom: 0;
            }
        }
    }
}
